import { useContext, useEffect } from 'react'
import FirebaseContext from 'components/FirebaseProvider'

export default function useFirebase() {
  const { init, ...context } = useContext(FirebaseContext) || {}
  useEffect(
    () => {
      if (typeof init === 'function') init()
    },
    [init]
  )
  return context
}
